<template>
  <div class="form">
    <!-- <span class="tishi">提示：二要素或三要素查询，无论查询是否成功，都会扣除1元查询费，请务必输入正确信息！</span> -->
    <el-form :inline="true" :model="search" ref="search" :rules="rules">
      <el-form-item prop="name">
        <el-input v-model.trim="search.name" class="id-name" clearable placeholder="">
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="idcard">
        <el-input v-model.trim="search.idcard" class="id-card" clearable placeholder="">
          <template slot="prepend">身份证</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model.trim="search.mobile" class="id-mobile" clearable placeholder="">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="query-btn" @click="query">查询</el-button>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import verify from '@/utils/verify';
export default {
  data () {
    var checkIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'));
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'));
      }
      callback();
    };
    var checkMobile = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else if (!verify.mobile(value)) {
        callback(new Error('请输入正确的手机号'));
      }
      callback();
    };
    return {
      search: { name: '', mobile: '', idcard: null, factor: 2 },
      /***
       * 为了搜索填入信息不自动触发校验
       * trigger只有'blur'和'change'两个值, 去除trigger还是会触发自动校验，
       * 暂时未找到禁用自动触发校验的方法
       * 只能将trigger 由 blur 改为 change，避开校验
       */
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'change' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'change' }],
        idcard: [{ required: true, validator: checkIdCard, trigger: 'change' }]
      },
      options: [
        { value: 0, label: '关闭校验' },
        { value: 1, label: '二要素校验' },
        { value: 2, label: '三要素校验' }
      ]
    };
  },
  methods: {
    query () {
      let check = false;
      this.$refs['search'].validate(res => (check = res));
      if (!check) return;
      if (this.search.mobile != '') { //三要素
        this.search.factor = this.options[2].value
      } else { //二要素
        this.search.factor = this.options[1].value
      }
      this.$emit('query', this.search);
    },
    //清空
    clear () {
      this.$refs.search.fields[0].resetField()
      this.$refs.search.fields[1].resetField()
      this.$refs.search.fields[2].resetField()
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  margin-bottom: 10px;

  .id-card.el-input {
    width: 300px;
  }
  .id-name.el-input {
    width: 200px;
  }
  .id-mobile.el-input {
    width: 300px;
  }

  .query-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }

  .required::before {
    content: "*";
    color: red;
    margin-left: 5px;
  }
}
.tishi{
    margin-bottom: 15px;
    font-size: 14px;
    color: red;
    display: block;
}
@media screen and (max-width: 480px) {
    .el-message-box{
      width: 80%!important;
    }
    .id-name.el-input {
      width: 300px!important;
    }
    .tishi{
      width: 320px;
    }
  }
</style>
